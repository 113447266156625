// $cyan: #0092d0;
$cyan: #21b1e7;
$cyan-light: #e5f6fc;
$navy: #003F69;
$white: #FFFFFF;
$black: #000000;


$bp-sm: 768px;
$bp-md: 1024px;
