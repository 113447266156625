//*------------------------------------*\
//    $INTRO
//*------------------------------------*/

.intro {
    align-items: center;
    display: none;
    height: 100vh;
    flex-direction: column;
    left: 0;
    margin: 0;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100vw;

    &__headline {
        animation: 2s ease-out 11s 1 reverse both running fade;
        font-family:'Gotham Bold';
        font-size: px-to-rem(70px);
        line-height: px-to-rem(70px);
        position: relative;
        text-align: center;
        text-transform: uppercase;
    }

    .advance {
        animation: 3s ease-out 1s 1 normal both running fade;
        background-color: transparent;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32"><path d="M24.533 30.507l-4.693-4.693 6.613-6.4h-26.453v-6.4h26.453l-6.613-6.4 4.693-4.693 11.947 11.947-2.347 2.347 2.347 2.347z" fill="black"></path><path d="M32.907 16.214l2.944-2.785 2.785 2.944-2.944 2.785-2.785-2.944z" fill="black"></path></svg>');
        border: 3px solid transparent;
        margin-top: 20vh;
        z-index: 3;

        &:hover,
        &:focus {
            background-color: transparent;
            background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32"><path d="M24.533 30.507l-4.693-4.693 6.613-6.4h-26.453v-6.4h26.453l-6.613-6.4 4.693-4.693 11.947 11.947-2.347 2.347 2.347 2.347z" fill="white"></path><path d="M32.907 16.214l2.944-2.785 2.785 2.944-2.944 2.785-2.785-2.944z" fill="white"></path></svg>');
            border: 3px solid transparent;
            cursor: pointer;
            outline: none;
        }
    }


    @media screen and (min-width: '#{$large-breakpoint}px') {
        &__headline {
            font-size: px-to-rem(160px);
            line-height: px-to-rem(160px);
        }
    }

    @media screen and (min-width: '#{$xx-large-breakpoint}px') {
        &__headline {
            width: 48vw;
        }
    }

    &-stage {
        &:before {
            animation: 20s ease-in 0.05s 1 normal both running photosslide;
            background-image: url('/images/introphotos.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: 200%;
            left: 0;
            position: absolute;
            top: 0;
            width: 200%;
            z-index: 2;

            @media screen and (min-width: '#{$large-breakpoint}px') {
               width: 100%;
            }
        }

        &:after {
            animation: 4s ease-in-out 0.05s 1 normal both running fadeclouds;
            background-color: rgba(255,255,255,1);
            background-image: url('/images/clouds.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }
}

.intro-stage  .intro {
    display: flex;
}
