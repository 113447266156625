//*------------------------------------*\
//    $SMALL BREAKPOINT
//*------------------------------------*/

html {
  height: 100%;
  position: relative;
}

body {
  animation: gradient 15s ease infinite;
  background: linear-gradient(-45deg, #4275D1, #137BD6, #7DC3F5, #A0D0F9);
  background-attachment: fixed;
  background-size: 400% 400%;
  color: map-get($colors, text);
  height: 100%;
  margin: 0;
  position: relative;
  z-index: 0;

  @include font-sizing();
}

.ngp-form.at {
    opacity: 0;

    .show & {
        opacity: 1;
        transition: opacity 1s ease-in;
    }
}

.visually-hidden,
.skip-nav {
    @include isVisuallyHidden();
}

main {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 90px 0 60px 0;
    perspective: 8px;
    perspective-origin: 0%;
    position: relative;
    width: 100vw;

    @media screen and (min-width: '#{$large-breakpoint}px') {
        display: grid;
        grid-template-columns: repeat(27, 1fr);
        grid-template-rows: 1fr;
        gap: 0px 0px;
        padding: 125px 0;

        & > .signup,
        & > .wall {
            grid-column: 4 / 25;
        }

        & > .intro {
            grid-column: 1 / 27;
        }

        > * {
            min-width: 0;
        }
    }

    &.modalshown {
       display: none;
    }
}

.skip-nav {
    left: -100px;
    position: absolute;
    top: -100px;

    &:focus {
        left: 0;
        position: relative;
        top: 0;
    }
}

:focus,
:focus-visible {
    outline: 3px solid #e2cc00;
}
