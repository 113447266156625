//*------------------------------------*\
//    $SITE HEADER
//*------------------------------------*/

.siteheader {
    align-items: flex-start;
    display: flex;
    height: 60px;
    justify-content: space-between;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &__headline {
        &-link {
            background-color: white;
            background-image: url('/images/sitelogo.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            display: block;
            height: 42px;
            text-decoration: none;
            width: 40vw;

            span {
                @include isVisuallyHidden();
            }

            @media screen and (min-width: '#{$small-breakpoint}px') {
               width: 231px;
            }
        }
    }

    .test {
        color: white;
    }

    &.modalshown .modal-trigger {
        display: none;
    }
}
