//*------------------------------------*\
//    $FEED
//*------------------------------------*/

.background {
    display: none;

    @media screen and (min-width: '#{$large-breakpoint}px') {
        background-image: url('/images/background.png');
        background-position: center top;
        background-repeat: repeat-y;
        background-size: 98%;
        display: grid;
        grid-column: 1 / 27;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr;
        gap: 0px 0px;
        left: 0;
        position: absolute;
        top: 150%;
        transform-origin: 0 50%;
        transform: translateZ(-8px) scale(2);
        width: 100%;
    }
}

.feed {
    padding: 26px;
    position: relative;
    top: 0;
    width: 100%;

    @media screen and (min-width: '#{$large-breakpoint}px') {
        display: grid;
    }

    &__item {
        background: white;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(24px);
        margin-bottom: 30px;
        padding: 30px 28px;

        &-attribution {
            font-family:'Gotham Bold';
            margin-top: 26px;
            text-transform: uppercase;
        }

        &.cta {
            background-image: url('/images/ctabackground.jpg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 76px 44px;
            text-align: center;

            .feed__item-headline {
                color: map-get($colors, white);
                font-family:'Gotham Bold';
                font-size: px-to-rem(32px);
                line-height: px-to-rem(36px);
                margin-bottom: 24px;
            }

            .button {
                margin: 0 auto;
            }
        }

        @media screen and (min-width: '#{$large-breakpoint}px') {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(28px);

            &:nth-of-type(18n+1),
            &:nth-of-type(18n+3),
            &:nth-of-type(18n+5),
            &:nth-of-type(18n+7),
            &:nth-of-type(18n+10),
            &:nth-of-type(18n+12),
            &:nth-of-type(18n+14),
            &:nth-of-type(18n+16), {
                grid-column: 2 / 9;
            }

            &:nth-of-type(18n+2),
            &:nth-of-type(18n+4),
            &:nth-of-type(18n+6),
            &:nth-of-type(18n+8),
            &:nth-of-type(18n+11),
            &:nth-of-type(18n+13),
            &:nth-of-type(18n+15),
            &:nth-of-type(18n+17), {
                grid-column: 5 / 12;
            }

            &.cta {
                grid-column: 4 / 10;
            }
        }

        &.in-viewport {
            opacity: 1;
            transition: 0.5s opacity ease-in;
        }

        &.outside-viewport {
            opacity: 0;
            transition: 0.5s opacity ease-out;
        }
    }
}

.wall {
    display: none;
}

.wall-stage .wall {
    display: block;
}
