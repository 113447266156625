//*------------------------------------*\
//    $GLOBAL ANIMATIONS
//*------------------------------------*/

/**
 * ----------------------------------------
 * gradient animation
 * ----------------------------------------
 */
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/**
 * ----------------------------------------
 * animation fade
 * ----------------------------------------
 */
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * clouds fade
 * ----------------------------------------
 */
@keyframes fadeclouds {
    0% {
        background-color: rgba(255,255,255,1);
        opacity: 1;
    }
    50% {
        background-color: rgba(255,255,255,0);
        opacity: 1;
    }
    100% {
        background-color: rgba(255,255,255,0);
        opacity: 0;
    }
}

/**
 * ----------------------------------------
 * photos slide down
 * ----------------------------------------
 */
@keyframes photosslide {
    0% {
       opacity: 0;
       transform: translateY(-100%);
    }
    30% {
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

/**
 * ----------------------------------------
 * loading animation
 * ----------------------------------------
 */
@keyframes loading {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
