.footer__content {
    background: #008acf;
    padding: 74px 37px 115px;

    @media only screen and (min-width:768px) {
        padding-bottom: 90px;
        padding-left: 0;
        padding-right: 0;
    }

    p {
        color: #000;
        font-size: 14px;
        line-height: 22px;
        margin: 0 auto 30px;
        max-width: 720px;
        text-align: center;
    }

    a {
        color: #000;
        font-family: inherit;
        text-decoration: underline;
        font-size: inherit;
    }
}
