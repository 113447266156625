/*!
9/11 Remembrance Wall Homepage CSS
*/

@import "base/reset";

@font-face{font-family:gotham_htfblack;src:url(/fonts/gothamhtf-black-webfont.eot);src:url(/fonts/gothamhtf-black-webfont.eot?#iefix) format("embedded-opentype"),url(/fonts/gothamhtf-black-webfont.woff2) format("woff2"),url(/fonts/gothamhtf-black-webfont.woff) format("woff"),url(/fonts/gothamhtf-black-webfont.ttf) format("truetype"),url(/fonts/gothamhtf-black-webfont.svg#gotham_htfblack) format("svg");font-weight:400;font-style:normal}
@font-face{font-family:gotham_htfbold;src:url(/fonts/gothamhtf-bold-webfont.eot);src:url(/fonts/gothamhtf-bold-webfont.eot?#iefix) format("embedded-opentype"),url(/fonts/gothamhtf-bold-webfont.woff2) format("woff2"),url(/fonts/gothamhtf-bold-webfont.woff) format("woff"),url(/fonts/gothamhtf-bold-webfont.ttf) format("truetype"),url(/fonts/gothamhtf-bold-webfont.svg#gotham_htfbold) format("svg");font-weight:400;font-style:normal}
@font-face{font-family:gotham_htfbook;src:url(/fonts/gothamhtf-book-webfont.eot);src:url(/fonts/gothamhtf-book-webfont.eot?#iefix) format("embedded-opentype"),url(/fonts/gothamhtf-book-webfont.woff2) format("woff2"),url(/fonts/gothamhtf-book-webfont.woff) format("woff"),url(/fonts/gothamhtf-book-webfont.ttf) format("truetype"),url(/fonts/gothamhtf-book-webfont.svg#gotham_htfbook) format("svg");font-weight:400;font-style:normal}

main.home {
    background-color: #fff;
    display: block;
    font-size: 20px;
    height: initial;
    line-height: 24px;
    font-family: gotham_htfbold;
    font-weight: 400;
    padding: 0;
    perspective: initial;
    width: initial;

    @media only screen and (min-width:768px) {
        font-size: 28px;
        line-height: 32px;
    }

    // this is a precompiled stylesheet from the original project
    // only used for the Hero area now
    // @TODO Pull these styles out from the original project
    // @TODO consolidate font files
    // @TODO Combine Home and not Home styles, as it should be
    @import "home/style";

    @import "home/constants";
    @import "home/mixins";

    @import "home/hero-updates"; // these are Hero style overrides from when the homepage was connected to Netlify CMS
    @import "home/component";
    @import "home/calltoaction";
    @import "home/logos";
    @import "home/video";
}

@import "home/footer";


/*!
9/11 Remembrance Wall CSS
*/
@import "base/constants/index";
@import "base/animations";
@import "base/functions";
@import "base/mixins/index";
@import "base/typography";
@import "base/icons";
@import "base/global";

/* components */
@import "components/index";
