/*!
Theme Name:
Theme URI:
Author:
Author URI:
Description:
Version: 0.0.1
*/
html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box
}

*,
:after,
:before {
    box-sizing: inherit
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

blockquote,
figure,
h1,
h2,
h3,
h4,
h5,
ol,
p,
ul {
    margin: 0;
    padding: 0
}

li,
main {
    display: block
}

ol,
ul {
    list-style: none
}

h1,
h2,
h3,
h4 {
    font-size: inherit
}

strong {
    font-weight: 700
}

.ajax,
.cta,
[role=button],
a {
    color: inherit
}

a {
    text-decoration: none
}

button {
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border: 0
}

:focus,
:focus-visible {
    outline: 3px solid #e2cc00;
}

img {
    max-width: 100%;
    height: auto;
    border: 0
}

blockquote,
q {
    quotes: none
}

a {
    font-size: 20px;
    line-height: 27px;
    font-family: gotham_htfblack
}

p {
    line-height: 26px;
    font-family: gotham_htfbook
}

h1,
p {
    font-size: 16px
}

h1 {
    line-height: 20px;
    font-stretch: normal;
    font-style: normal
}

@media only screen and (min-width:768px) {
    h1 {
        font-size: 32px;
        line-height: 42px
    }
}

h2 {
    font-size: 18px;
    line-height: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400
}

@media only screen and (min-width:768px) {
    h2 {
        font-size: 24px;
        line-height: 28px
    }
}

h3 {
    font-size: 16px;
    line-height: 20px;
    font-stretch: normal;
    font-style: normal
}

@media only screen and (min-width:768px) {
    h3 {
        font-size: 20px;
        line-height: 24px
    }
}

h4 {
    font-size: 22px;
    line-height: 32px;
    font-stretch: normal;
    font-style: normal
}

@media only screen and (min-width:768px) {
    h4 {
        font-size: 24px;
        line-height: 34px
    }
}

.center {
    text-align: center
}

h1 {
    color: #fff
}

h2 {
    color: #000
}

h3,
h4 {
    color: #fff
}

a {
    color: #008acf
}

body,
p {
    color: #000
}

strong {
    font-family: gotham_htfbold;
    font-weight: 400
}

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
    transition-duration: 50ms
}

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
    transition-delay: 50ms
}

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
    transition-duration: .1s
}

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s
}

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
    transition-duration: .15s
}

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
    transition-delay: .15s
}

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
    transition-duration: .2s
}

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s
}

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
    transition-duration: .25s
}

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
    transition-delay: .25s
}

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
    transition-duration: .3s
}

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
    transition-delay: .3s
}

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
    transition-duration: .35s
}

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
    transition-delay: .35s
}

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s
}

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
    transition-delay: .4s
}

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
    transition-duration: .45s
}

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
    transition-delay: .45s
}

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s
}

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
    transition-delay: .5s
}

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
    transition-duration: .55s
}

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
    transition-delay: .55s
}

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
    transition-duration: .6s
}

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
    transition-delay: .6s
}

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
    transition-duration: .65s
}

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
    transition-delay: .65s
}

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
    transition-duration: .7s
}

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
    transition-delay: .7s
}

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
    transition-duration: .75s
}

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
    transition-delay: .75s
}

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
    transition-duration: .8s
}

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
    transition-delay: .8s
}

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
    transition-duration: .85s
}

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
    transition-delay: .85s
}

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
    transition-duration: .9s
}

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
    transition-delay: .9s
}

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
    transition-duration: .95s
}

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
    transition-delay: .95s
}

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s
}

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
    transition-delay: 1s
}

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
    transition-duration: 1.05s
}

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
    transition-delay: 1.05s
}

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
    transition-duration: 1.1s
}

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
    transition-delay: 1.1s
}

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
    transition-duration: 1.15s
}

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
    transition-delay: 1.15s
}

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
    transition-duration: 1.2s
}

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
    transition-delay: 1.2s
}

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
    transition-duration: 1.25s
}

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
    transition-delay: 1.25s
}

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
    transition-duration: 1.3s
}

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
    transition-delay: 1.3s
}

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
    transition-duration: 1.35s
}

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
    transition-delay: 1.35s
}

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
    transition-duration: 1.4s
}

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
    transition-delay: 1.4s
}

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
    transition-duration: 1.45s
}

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
    transition-delay: 1.45s
}

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
    transition-duration: 1.5s
}

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
    transition-delay: 1.5s
}

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
    transition-duration: 1.55s
}

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
    transition-delay: 1.55s
}

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
    transition-duration: 1.6s
}

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
    transition-delay: 1.6s
}

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
    transition-duration: 1.65s
}

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
    transition-delay: 1.65s
}

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
    transition-duration: 1.7s
}

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
    transition-delay: 1.7s
}

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
    transition-duration: 1.75s
}

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
    transition-delay: 1.75s
}

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
    transition-duration: 1.8s
}

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
    transition-delay: 1.8s
}

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
    transition-duration: 1.85s
}

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
    transition-delay: 1.85s
}

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
    transition-duration: 1.9s
}

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
    transition-delay: 1.9s
}

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
    transition-duration: 1.95s
}

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
    transition-delay: 1.95s
}

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
    transition-duration: 2s
}

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
    transition-delay: 2s
}

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
    transition-duration: 2.05s
}

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
    transition-delay: 2.05s
}

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
    transition-duration: 2.1s
}

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
    transition-delay: 2.1s
}

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
    transition-duration: 2.15s
}

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
    transition-delay: 2.15s
}

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
    transition-duration: 2.2s
}

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
    transition-delay: 2.2s
}

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
    transition-duration: 2.25s
}

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
    transition-delay: 2.25s
}

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
    transition-duration: 2.3s
}

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
    transition-delay: 2.3s
}

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
    transition-duration: 2.35s
}

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
    transition-delay: 2.35s
}

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
    transition-duration: 2.4s
}

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
    transition-delay: 2.4s
}

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
    transition-duration: 2.45s
}

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
    transition-delay: 2.45s
}

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
    transition-duration: 2.5s
}

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
    transition-delay: 2.5s
}

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
    transition-duration: 2.55s
}

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
    transition-delay: 2.55s
}

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
    transition-duration: 2.6s
}

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
    transition-delay: 2.6s
}

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
    transition-duration: 2.65s
}

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
    transition-delay: 2.65s
}

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
    transition-duration: 2.7s
}

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
    transition-delay: 2.7s
}

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
    transition-duration: 2.75s
}

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
    transition-delay: 2.75s
}

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
    transition-duration: 2.8s
}

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
    transition-delay: 2.8s
}

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
    transition-duration: 2.85s
}

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
    transition-delay: 2.85s
}

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
    transition-duration: 2.9s
}

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
    transition-delay: 2.9s
}

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
    transition-duration: 2.95s
}

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
    transition-delay: 2.95s
}

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
    transition-duration: 3s
}

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
    transition-delay: 0
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
    transition-delay: 3s
}

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75)
}

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease
}

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
    transition-timing-function: ease-in
}

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
    transition-timing-function: ease-out
}

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
    transition-timing-function: ease-in-out
}

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045)
}

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715)
}

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.445, .05, .55, .95)
}

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
}

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955)
}

[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0)
}

[data-aos=fade-up] {
    transform: translate3d(0, 100px, 0)
}

[data-aos=fade-down] {
    transform: translate3d(0, -100px, 0)
}

[data-aos=fade-right] {
    transform: translate3d(-100px, 0, 0)
}

[data-aos=fade-left] {
    transform: translate3d(100px, 0, 0)
}

[data-aos=fade-up-right] {
    transform: translate3d(-100px, 100px, 0)
}

[data-aos=fade-up-left] {
    transform: translate3d(100px, 100px, 0)
}

[data-aos=fade-down-right] {
    transform: translate3d(-100px, -100px, 0)
}

[data-aos=fade-down-left] {
    transform: translate3d(100px, -100px, 0)
}

[data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, transform
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1)
}

[data-aos=zoom-in] {
    transform: scale(.6)
}

[data-aos=zoom-in-up] {
    transform: translate3d(0, 100px, 0) scale(.6)
}

[data-aos=zoom-in-down] {
    transform: translate3d(0, -100px, 0) scale(.6)
}

[data-aos=zoom-in-right] {
    transform: translate3d(-100px, 0, 0) scale(.6)
}

[data-aos=zoom-in-left] {
    transform: translate3d(100px, 0, 0) scale(.6)
}

[data-aos=zoom-out] {
    transform: scale(1.2)
}

[data-aos=zoom-out-up] {
    transform: translate3d(0, 100px, 0) scale(1.2)
}

[data-aos=zoom-out-down] {
    transform: translate3d(0, -100px, 0) scale(1.2)
}

[data-aos=zoom-out-right] {
    transform: translate3d(-100px, 0, 0) scale(1.2)
}

[data-aos=zoom-out-left] {
    transform: translate3d(100px, 0, 0) scale(1.2)
}

[data-aos^=slide][data-aos^=slide] {
    transition-property: transform
}

[data-aos^=slide][data-aos^=slide].aos-animate {
    transform: translateZ(0)
}

[data-aos=slide-up] {
    transform: translate3d(0, 100%, 0)
}

[data-aos=slide-down] {
    transform: translate3d(0, -100%, 0)
}

[data-aos=slide-right] {
    transform: translate3d(-100%, 0, 0)
}

[data-aos=slide-left] {
    transform: translate3d(100%, 0, 0)
}

[data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: transform
}

[data-aos=flip-left] {
    transform: perspective(2500px) rotateY(-100deg)
}

[data-aos=flip-left].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-right] {
    transform: perspective(2500px) rotateY(100deg)
}

[data-aos=flip-right].aos-animate {
    transform: perspective(2500px) rotateY(0)
}

[data-aos=flip-up] {
    transform: perspective(2500px) rotateX(-100deg)
}

[data-aos=flip-up].aos-animate {
    transform: perspective(2500px) rotateX(0)
}

[data-aos=flip-down] {
    transform: perspective(2500px) rotateX(100deg)
}

[data-aos=flip-down].aos-animate {
    transform: perspective(2500px) rotateX(0)
}

.slick-slider {
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list,
.slick-slider {
    position: relative;
    display: block
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translateZ(0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.alignright {
    float: none
}

@media screen and (min-width:768px) {
    .alignright {
        float: right
    }
}

.alignleft {
    float: none
}

@media screen and (min-width:768px) {
    .alignleft {
        float: left
    }
}

.aligncenter {
    margin: 0 auto;
    max-width: 100%
}

.icon {
    display: inline-block;
    height: 1rem;
    width: 1rem
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: relative
}

body {
    overflow-x: hidden
}

* {
    box-sizing: border-box
}

section {
    position: relative;
    width: 100%
}

section:after {
    clear: both;
    content: "";
    display: block
}

.container {
    padding: 0 40px;
    margin: 0 auto;
    max-width: 1300px;
    width: 100%
}

input::-webkit-input-placeholder {
    line-height: normal !important
}

.mCSB_draggerContainer {
    background: #e5e5e5 !important;
    border-radius: 10px
}

.mCS-light.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #008acf !important
}

.mCSB_scrollTools {
    opacity: 1 !important;
    width: 14px !important
}

@media only screen and (min-width:1024px) {
    .mCSB_scrollTools {
        width: 17px !important
    }
}

.mCS-light.mCSB_scrollTools .mCSB_draggerRail {
    background: none !important
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 12px !important
}

@media only screen and (min-width:1024px) {
    .mCSB_scrollTools .mCSB_draggerRail {
        width: 17px !important
    }
}

.mCS-light.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 12px;
    opacity: 1;
    border-radius: 10px
}

@media only screen and (min-width:1024px) {
    .mCS-light.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 17px
    }
}

.button {
    padding: 18px 28px;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 5px 0 rgba(0, 0, 0, .16);
    color: #008acf;
    font-weight: 400;
    text-transform: uppercase
}

.button--large {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    max-width: 350px
}

.anniversary__image+.button--large {
    margin-top: 74px;
}

.hero {
    margin: 0
}

@media only screen and (min-width:768px) {
    .hero {
        background-image: url(/assets/img/hero-bg.jpg);
        background-size: cover;
        overflow-x: hidden
    }
}

@media only screen and (min-width:1024px) {
    .hero {
        display: grid;
        grid-template-columns: 20% 60% 20%
    }
}

.hero__title-img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    width: 100%;
}

.hero__title-img--mobile {
    margin-bottom: 67px;
    max-width: 317px
}

@media only screen and (min-width:768px) {
    .hero__title-img--mobile {
        display: none
    }
}

.hero__subtitle-img {
    margin-right: auto;
    margin-bottom: 66px;
    margin-left: auto;
    display: block;
    max-width: 317px
}

@media only screen and (min-width:768px) {
    .hero__subtitle-img {
        margin-bottom: 72px;
        min-width: 395px
    }
}

.hero__left,
.hero__right {
    max-width: 320px
}

.hero__left {
    display: none
}

@media only screen and (min-width:1024px) {
    .hero__left {
        display: block
    }
}

.hero__left img:first-child,
.hero__left img:nth-child(2) {
    transform: translate(-100px, -80px)
}

@media only screen and (min-width:1024px) {
    .hero__left img {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }

    .hero__left img:first-child {
        max-width: 360px
    }

    .hero__left img:nth-child(2) {
        max-width: 320px
    }
}

@media only screen and (min-width:1200px) {
    .hero__left img {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }

    .hero__left img:first-child {
        max-width: 440px
    }

    .hero__left img:nth-child(2) {
        max-width: 378px
    }
}

.hero__right {
    display: none
}

@media only screen and (min-width:1024px) {
    .hero__right {
        display: block
    }
}

.hero__right img:first-child {
    transform: translate(-40px, -32px)
}

.hero__right img:nth-child(2) {
    transform: translateY(-60px)
}

.hero__right img:nth-child(3) {
    transform: translate(80px, 40px)
}

.hero__right img:nth-child(4) {
    transform: translate(28%, 80%)
}

@media only screen and (min-width:1024px) {
    .hero__right img {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }

    .hero__right img:first-child {
        max-width: 400px
    }

    .hero__right img:nth-child(2) {
        max-width: 395px
    }

    .hero__right img:nth-child(3),
    .hero__right img:nth-child(4) {
        max-width: 240px
    }
}

@media only screen and (min-width:1200px) {
    .hero__right img {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content
    }

    .hero__right img:first-child {
        max-width: 440px
    }

    .hero__right img:nth-child(2) {
        max-width: 378px
    }
}

.hero__middle {
    margin: 0;
    align-items: center;
    background: url(/assets/img/hero-bg.jpg) 50% no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    text-align: center
}

@media only screen and (min-width:768px) {
    .hero__middle {
        background-image: none;
        display: block;
        text-align: center
    }
}

.hero__middle-intro {
    margin-right: 37px;
    margin-left: 37px;
    padding-bottom: 85px
}

@media only screen and (min-width:768px) {
    .hero__middle-intro {
        padding-bottom: 0
    }
}

.hero__middle-intro h1,
.hero__middle-intro h4 {
    text-transform: uppercase;
    text-align: center
}

.hero__middle-intro h1 {
    margin-bottom: 18px;
    font-size: 26px;
    line-height: 32px;
    display: inline-block;
    max-width: 300px
}

@media only screen and (min-width:768px) {
    .hero__middle-intro h1 {
        margin-bottom: 0;
        font-size: 26px;
        line-height: 32px;
        max-width: 100%
    }
}

.hero__middle-intro h4 {
    font-size: 26px;
    line-height: 32px;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    font-weight: 700;
    max-width: 330px
}

@media only screen and (min-width:768px) {
    .hero__middle-intro h4 {
        font-size: 26px;
        line-height: 42px;
        max-width: 490px
    }
}

.hero__middle-intro a {
    margin-right: auto;
    margin-bottom: 64px;
    margin-left: auto;
    background: #fff;
    border-radius: 5px;
    color: #008acf;
    display: flex;
    font-weight: 700;
    justify-content: center;
    max-width: 250px;
    text-transform: uppercase
}

@media only screen and (min-width:768px) {
    .hero__middle-intro a {
        display: none
    }
}

.hero__middle-intro p {
    color: #fff;
    letter-spacing: 4px;
    opacity: .5
}

.hero__middle-content {
    padding-top: 50px;
    padding-right: 37px;
    padding-left: 37px;
    margin-right: auto;
    margin-left: auto;
    background-image: linear-gradient(rgba(0, 0, 0, .527), rgba(0, 0, 0, .5));
    display: flex;
    flex-direction: column
}

@media only screen and (min-width:768px) {
    .hero__middle-content {
        padding-top: 0;
        padding-right: 0;
        padding-left: 0;
        background-image: none;
        max-width: 720px
    }
}

.hero__middle-content p {
    margin-top: 10px;
    margin-bottom: 0;
    color: #fff;
    text-align: center
}

.hero__middle-content p:first-child {
    margin-top: 0
}

.hero__middle-content a {
    display: none
}

@media only screen and (min-width:768px) {
    .hero__middle-content a {
        margin: 50px auto 50px;
        background: #fff;
        border-radius: 5px;
        color: #008acf;
        display: flex;
        justify-content: center;
        text-transform: uppercase
    }
}

.hero__middle-content h1 {
    font-size: 16px;
    line-height: 24px;
    margin-top: 48px;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase
}

@media only screen and (min-width:768px) {
    .hero__middle-content h1 {
        font-size: 32px;
        line-height: 42px
    }
}

@media only screen and (min-width:768px) {
    .hero__middle-content h1 br:first-child {
        display: none
    }
}

.hero__middle-content--image {
    margin-top: 23px;
    margin-bottom: 132px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center
}

@media only screen and (min-width:768px) {
    .hero__middle-content--image {
        margin-bottom: 95px
    }
}

.hero__middle-content--image img {
    filter: brightness(0) invert(1);
    max-width: 300px;
    -o-object-fit: cover;
    object-fit: cover
}

@media only screen and (min-width:768px) {
    .hero__middle-content--image img {
        max-width: 720px
    }
}

.hero__middle-content--image h2 {
    margin-top: -35px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    max-width: 250px;
    position: relative;
    text-align: center;
    text-transform: uppercase
}

.about-ticket {
    padding-right: 37px;
    padding-bottom: 93px;
    padding-left: 37px;
    background: url(assets/img/cloud.png) rgba(0, 138, 207, .85) bottom fixed no-repeat;
    background-size: cover
}

@media only screen and (min-width:768px) {
    .about-ticket {
        padding-bottom: 125px
    }
}

.about-ticket__content-logo {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: -90px
}

@media only screen and (min-width:768px) {
    .about-ticket__content-logo {
        top: -180px
    }
}

.about-ticket__content-logo img {
    justify-content: center;
    max-width: 370px
}

@media only screen and (min-width:768px) {
    .about-ticket__content-logo img {
        padding-bottom: 45px;
        max-width: 700px
    }
}

@media only screen and (min-width:1024px) {
    .about-ticket__content-logo img {
        max-width: 746px
    }
}

.about-ticket__content-text {
    margin: -45px auto 74px;
    max-width: 720px
}

@media only screen and (min-width:768px) {
    .about-ticket__content-text {
        margin-top: -137px
    }
}

.about-ticket__content-text h1 {
    padding-bottom: 49px;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase
}

@media only screen and (min-width:768px) {
    .about-ticket__content-text h1 {
        padding-bottom: 40px;
        font-size: 32px;
        line-height: 42px
    }
}

.about-ticket__content-text p {
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
    text-align: center
}

.about-ticket__content-text p:nth-of-type(3) {
    margin-bottom: 88px
}

@media only screen and (min-width:768px) {
    .about-ticket__content-text p:nth-of-type(3) {
        margin-bottom: 45px
    }
}

.about-ticket__content-text--wrapper {
    margin: 0 auto;
    text-align: center
}

@media only screen and (min-width:768px) {
    .about-ticket__content-text--wrapper {
        font-size: 20px;
        line-height: 27px
    }
}

.about-ticket__content-text--wrapper a {
    display: inline-block
}

.about-ticket__image {
    display: grid;
    grid-template-columns: repeat(2, 150px);
    grid-gap: 10px;
    justify-content: center
}

@media only screen and (min-width:768px) {
    .about-ticket__image {
        grid-template-columns: repeat(4, 150px);
        text-align: center
    }
}

.remembrance-wall {
    background: url(assets/img/remembrance-bg-img.png) bottom fixed no-repeat;
    background-size: cover;
    background-position: 50%
}

.remembrance-wall__content {
    padding: 73px 37px;
    margin-right: auto;
    margin-left: auto
}

@media only screen and (min-width:768px) {
    .remembrance-wall__content {
        padding: 150px 0;
        max-width: 720px
    }
}

.remembrance-wall__content h2 {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 41px;
    font-family: gotham_htfbold;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase
}

@media only screen and (min-width:768px) {
    .remembrance-wall__content h2 {
        font-size: 32px;
        line-height: 42px
    }
}

.remembrance-wall__content .button {
    margin-top: 59px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    width: 100%
}

@media only screen and (min-width:768px) {
    .remembrance-wall__content .button {
        margin-top: 37px;
        max-width: 450px
    }
}

.remembrance-wall__content p {
    margin-bottom: 10px;
    text-align: center
}

.remembrance-wall__content p:last-of-type {
    margin-bottom: 0
}

.remembrance-wall__content p,
.remembrance-wall__content p a {
    font-size: 16px;
    line-height: 26px
}

.remembrance-wall__content p a {
    padding: 0;
    background: none;
    color: #008acf;
    display: unset;
    justify-content: unset;
    text-transform: none;
    text-decoration: underline
}

.anniversary {
    padding: 90px 37px;
    background: #fff
}

@media only screen and (min-width:1024px) {
    .anniversary {
        padding-top: 150px;
        padding-bottom: 193px
    }
}

.anniversary h2 {
    margin-right: auto;
    margin-bottom: 47px;
    margin-left: auto;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    max-width: 260px
}

@media only screen and (min-width:1024px) {
    .anniversary h2 {
        margin-bottom: 36px;
        font-size: 32px;
        line-height: 42px;
        max-width: 100%
    }
}

.anniversary__image ul {
    margin-right: auto;
    margin-left: auto;
    -moz-column-gap: 15px;
    column-gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px
}

.anniversary__image ul,
.anniversary__image ul li {
    align-items: center;
    display: flex;
    justify-content: center;
}

.anniversary__image ul.primary li {
    width: 100%;
}

.anniversary__image ul.secondary li {
    width: 76%;
}

.anniversary__image ul.tertiary li {
    width: 36%;
}

@media only screen and (min-width:768px) {
    .anniversary__image ul {
        margin-bottom: 40px;
    }

    .anniversary__image ul,
    .anniversary__image ul li {
        flex: 1 1 0;
        width: auto;
    }

    .anniversary__image ul.anniversary__image-list {
        margin-bottom: 15px
    }
}

.anniversary__image ul.anniversary__image-list>li {
    min-height: 184px;
    border: 1px solid #ccc;
    margin-bottom: 16px;
    margin-left: 0;
    padding: 0 1%;
}

.anniversary__image ul.anniversary__image-list>li img {
    max-height: 130px;
}

@media only screen and (min-width:768px) {
    .anniversary__image ul.anniversary__image-list>li {
        min-height: 100%;
    }

    .anniversary__image-list.primary {
        height: 140px;
        max-width: 1088px
    }

    .anniversary__image-list.secondary {
        height: 124px;
        max-width: 904px
    }

    .anniversary__image-list.tertiary {
        height: 124px;
        max-width: 1160px
    }
}

.anniversary .button {
    background: #008acf;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center
}

@media only screen and (min-width:1024px) {
    .anniversary .button {
        margin-right: auto;
        margin-left: auto;
        max-width: 690px
    }
}

.footer__content {
    padding: 74px 37px 115px;
    background: #008acf
}

@media only screen and (min-width:768px) {
    .footer__content {
        padding-right: 0;
        padding-bottom: 90px;
        padding-left: 0
    }
}

.footer__content p {
    margin-right: auto;
    margin-left: auto;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    max-width: 720px;
    text-align: center
}

.footer__content a {
    color: #fff;
    font-size: inherit;
    font-family: inherit;
    text-decoration: underline
}

.footer__ticket {
    padding-top: 74px;
    padding-bottom: 110px
}

@media only screen and (min-width:768px) {
    .footer__ticket {
        padding-top: 72px;
        padding-bottom: 87px
    }
}

.hero__ticket--left,
.hero__ticket--right {
    display: none;
    position: relative
}

@media only screen and (min-width:1024px) {

    .hero__ticket--left,
    .hero__ticket--right {
        display: block
    }
}

@media only screen and (min-width:1024px) {
    .hero__ticket--left img {
        max-width: 285px;
        transform: translate(-50px, 30px)
    }
}

@media only screen and (min-width:1024px) {
    .hero__ticket--right img {
        max-width: 340px;
        transform: translateY(-80px)
    }
}

.hero__ticket-title-img {
    display: none
}

@media only screen and (min-width:768px) {
    .hero__ticket-title-img {
        margin-right: auto;
        margin-bottom: 40px;
        margin-left: auto;
        display: block;
        max-width: 537px;
        width: 100%
    }
}

.hero__ticket-title-img--mobile {
    margin-bottom: 69px;
    max-width: 317px
}

@media only screen and (min-width:768px) {
    .hero__ticket-title-img--mobile {
        display: none
    }
}

.hero__ticket-subtitle-img {
    margin-bottom: 82px;
    max-width: 317px
}

@media only screen and (min-width:768px) {
    .hero__ticket-subtitle-img {
        margin-bottom: 64px;
        min-width: 395px
    }
}

.hero__middle--ticket {
    padding-top: 70px;
    padding-bottom: 75px
}

@media only screen and (min-width:768px) {
    .hero__middle--ticket {
        padding-bottom: 164px
    }
}

.ticket__about {
    background-image: none;
    background: #008acf
}

.ticket__about,
.ticket__about-content-logo img:first-child {
    padding-bottom: 0
}

@media only screen and (min-width:768px) {
    .ticket__about-content-text {
        margin-top: -133px
    }
}

.ticket__about-content-text--wrapper a {
    margin-bottom: 104px
}

@media only screen and (min-width:768px) {
    .ticket__about-content-text--wrapper a {
        margin-bottom: 64px
    }
}

.ticket__about-content-text p {
    font-size: 18px;
    line-height: 30px
}

.ticket__about-content-text p:nth-of-type(3) {
    margin-bottom: 59px
}

@media only screen and (min-width:768px) {
    .ticket__about-content-text p:nth-of-type(3) {
        margin-bottom: 69px
    }
}

.ticket__about-content-image {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 170px);
    justify-content: center
}

@media only screen and (min-width:768px) {
    .ticket__about-content-image {
        grid-template-columns: repeat(2, 350px)
    }
}

.ticket__about-content-image img {
    max-width: 170px;
    width: 100%
}

@media only screen and (min-width:768px) {
    .ticket__about-content-image img {
        max-width: 100%
    }
}

.hero__middle-content ul {
    margin: 0 auto;
    width: 60%;
}

.hero__middle-content ul li {
    display: list-item;
    margin-top: 10px;
    margin-bottom: 0;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    font-family: gotham_htfbook;
    text-align: left;
    list-style: disc;
}

.donatecallout {
    padding: 90px 37px 0 37px;
    background: #fff
}

@media only screen and (min-width:1024px) {
    .donatecallout {
        padding: 150px 37px 0 37px;
    }
}

.donatecallout h2 {
    margin-right: auto;
    margin-bottom: 47px;
    margin-left: auto;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    max-width: 260px
}

@media only screen and (min-width:1024px) {
    .donatecallout h2 {
        margin-bottom: 36px;
        font-size: 32px;
        line-height: 42px;
        max-width: 100%
    }
}

.donatecallout__image ul {
    margin-right: auto;
    margin-left: auto;
    -moz-column-gap: 15px;
    column-gap: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px
}

.donatecallout .button {
    background: #008acf;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center
}

@media only screen and (min-width:1024px) {
    .donatecallout .button {
        margin-right: auto;
        margin-left: auto;
        max-width: 225px
    }
}

/*# sourceMappingURL=style.css.map */
