//*------------------------------------*\
//    $BREAKPOINT VARIABLES
//*------------------------------------*/

$small-breakpoint: 480;
$medium-breakpoint: 916;
$med-large-breakpoint: 916;
$large-breakpoint: 1024;
$x-large-breakpoint: 1180;
$xx-large-breakpoint: 1300;
