//*------------------------------------*\
//    $BUTTON STYLES
//*------------------------------------*/

.button {
    background-color: map-get($colors, white);
    border: none;
    color: map-get($colors, black);
    display: block;
    font-family: $display-font;
    margin: 0;
    padding: 27px 30px 26px;
    text-align: center;
    @include font-sizing('medium');

    .feed & {
       background-color: map-get($colors, lightblue);
    }

    &:hover,
    &:focus {
        background-color: map-get($colors, lightblue);
        cursor: pointer;
        outline: none;

        .feed & {
            background-color: map-get($colors, white);
        }
    }

    &.advance {
        background-color: transparent;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32"><path d="M24.533 30.507l-4.693-4.693 6.613-6.4h-26.453v-6.4h26.453l-6.613-6.4 4.693-4.693 11.947 11.947-2.347 2.347 2.347 2.347z" fill="white"></path><path d="M32.907 16.214l2.944-2.785 2.785 2.944-2.944 2.785-2.785-2.944z" fill="white"></path></svg>');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
        border: 3px solid map-get($colors, white);
        height: px-to-rem(50px);
        padding: none;
        width: px-to-rem(50px);

        &:hover,
        &:focus {
            background-color: map-get($colors, lightblue);
            background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32"><path d="M24.533 30.507l-4.693-4.693 6.613-6.4h-26.453v-6.4h26.453l-6.613-6.4 4.693-4.693 11.947 11.947-2.347 2.347 2.347 2.347z" fill="black"></path><path d="M32.907 16.214l2.944-2.785 2.785 2.944-2.944 2.785-2.785-2.944z" fill="black"></path></svg>');
            border: 3px solid map-get($colors, lightblue);
            cursor: pointer;
            outline: none;
        }

        span {
            @include isVisuallyHidden();
        }
    }
}

.button--alt {
    background-color: map-get($colors, black);
    color: map-get($colors, white);
    height: px-to-rem(42px);
    padding: 13px 30px;

    &:hover,
    &:focus {
        background-color: map-get($colors, white);
        color: map-get($colors, black);
    }
}
