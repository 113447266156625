.component {
    position: relative;

    &__background {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    &__content {
        position: relative;
        z-index: 2;
    }
}
