// ======================================
// Breakpoint
// ======================================
@mixin bp($size, $type: "min-width") {
    @media screen and ($type: '#{$size}') {
      @content;
    }
}

// ======================================
// Typography Presets
// ======================================
@mixin headline() {
    color: inherit;
    font-family: gotham_htfbook;
    font-size: 32px;
    line-height: 1.35;

    @include bp($bp-sm) {
        font-size: 40px;
    }
}

@mixin subhead() {
    color: inherit;
    font-family: gotham_htfbook;
    font-size: 18px;
    line-height: 1.35;
}
