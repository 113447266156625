//*------------------------------------*\
//    $SIGNUP
//*------------------------------------*/

.signup {
    background-color: map-get($colors, white);
    margin: 0 auto;
    padding: 40px 20px;
    width: calc(100% - 60px);

    form {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        order: 3;

        fieldset {
            width: 100%;
        }
    }

    .at-inner {
        display: flex;
        flex-direction: column;
    }

    .ContactInformation .at-legend,
    .AdditionalInformation .at-legend,
    .checkbox-list-label,
    .PersonalUrl {
        display: none;
    }

    .at-title,
    .thankYou h2:first-of-type,
    .thankYou h3:first-of-type,
    .thankYou h4:first-of-type {
        font-family: $display-font;
        @include font-sizing('large');
        order: 1;
        width: 100%;
    }

    .thankYou h2:first-of-type,
    .thankYou h3:first-of-type,
    .thankYou h4:first-of-type {
        text-align: left;
    }

    .HeaderHtml {
        margin-top: 18px;
        order: 2;
        width: 100%;

        p + p {
            margin-top: 18px;
        }

        h2, h3, h4 {
           font-family: $display-font;
           @include font-sizing('default');
       }
    }

   .FooterHtml {
       margin-top: 8px;
       order: 4;
       width: 100%;
       @include font-sizing('small');

       h2, h3, h4 {
           margin-top: 24px;
       }
   }

   .thankYou p {
      margin-top: 18px;
      @include font-sizing();
      text-align: left;
   }

   .FirstName {
       margin-top: 18px;
       order: 1;
   }

   .at-row.PostalCode.City.StateProvince {
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;
       order: 1;
       width: 100%;
   }

    .at-text.City {
      margin-top: 18px;
      order: 3;
      width: 100%;
    }

    .at-select.StateProvince {
        margin-top: 18px;
        order: 2;
        width: calc(50% - 10px);

        select {
            appearance: none;
            background-color: map-get($colors, white);
            background-image: url(/images/downarrow.png);
            background-repeat: no-repeat;
            background-position: calc(100% - 8px) center;
            background-size: 15px 7.5px;
            border: 2px solid map-get($colors, black);
            border-radius: 0;
            box-sizing: border-box;
            box-shadow: none;
            display: block;
            -moz-appearance: none;
            -webkit-appearance: none;
            padding: 14px;
            width: 100%;

            @include font-sizing();

            &:focus {
               border-color: map-get($colors, darkblue);
           }
        }
    }

    .at-text.PostalCode {
        margin-right: 20px;
        margin-top: 18px;
        order: 1;
        width: calc(50% - 10px);

        input {
            display: block;
            width: 100%;
        }
    }

    .EmailAddress {
        margin-top: 18px;
        order: 3;
    }

    .AdditionalInformation {
        margin-top: 18px;
        order: 4;
    }

    .at-form-submit {
        margin-top: 18px;
        order: 6;
    }

   input[type="text"],
   input[type="email"],
   input[type="tel"],
   textarea {
       border: 2px solid map-get($colors, black);
       box-shadow: none;
       padding: 14px;

       @include font-sizing();

       &:focus {
           border-color: map-get($colors, darkblue);
       }
   }

   textarea {
       font-family: $copy-font;
       min-height: 316px;
       resize: vertical;
   }

   .FirstName > input,
   .City > input,
   .EmailAddress > input,
   textarea {
       width: 100%;
   }

   .at-check {
       display: block;
       margin-top: 18px;

       input {
           @include isVisuallyHidden();
       }

       .at-checkbox-title-container {
           display: block;
           padding-left: 20px;
           position: relative;

           span {
               font-size: 12px;
           }

           &:before {
              background-image: url(/images/checksprite.png);
              background-repeat: no-repeat;
              background-position: 0 top;
              background-size: 16px;
              content: '';
              display: block;
              height: 16px;
              left: 0;
              position: absolute;
              top: 0;
              width: 16px;
           }
       }

       input + .at-checkbox-title-container span {
            &:after {
                color: map-get($colors, red);
                content: 'Acceptance of terms and conditions is required.';
                display: block;
            }
       }

       input:checked + .at-checkbox-title-container span {
              &:after {
                  content: none;
              }
        }

       input:checked + .at-checkbox-title-container:before {
            background-position: 0 bottom;
       }

       &:hover {
           cursor: pointer;
       }
   }

   input[type="submit"] {
       background-color: map-get($colors, lightblue);
       border: none;
       color: map-get($colors, black);
       display: block;
       font-family: $display-font;
       padding: 18px 24px;
       text-align: center;
       @include font-sizing('medium');

       &:hover,
       &:focus {
          background-color: map-get($colors, darkblue);
          color: map-get($colors, white);
          cursor: pointer;
          outline: none;
       }
   }

    .thankYou a {
      background-color: map-get($colors, lightblue);
      border: none;
      color: map-get($colors, black);
      display: inline-block;
      font-family: $display-font;
      margin: 0 auto;
      padding: 18px 24px;
      text-align: center;
      @include font-sizing('medium');

      &:hover {
         background-color: map-get($colors, black);
         color: map-get($colors, white);
         cursor: pointer;
      }
    }

   .error {
       color: map-get($colors, red);

       input,
       textarea {
           border-color: map-get($colors, red);
       }

       &.at-select.StateProvince select {
           border-color: map-get($colors, red);
       }
   }

   small.error {
       display: block;
       margin: 8px 0 0 0;

       @include font-sizing('small');
   }

   .didYouMean a {
       color: map-get($colors, darkblue);
       font-weight: bold;

       &:hover {
          text-decoration: underline;
       }
   }

   .at-error-console {
        color: map-get($colors, red);
        font-weight: bold;
        margin-bottom: 18px;
        position: relative;
        width: 90%;

        .at-dismiss {
            font-weight: bold;
            position: absolute;
            right: -8px;
            top: -10px;

            &:hover {
                color: map-get($colors, black);
            }
        }
     }

   @media screen and (min-width: '#{$large-breakpoint}px') {
       display: grid;
       grid-template-columns: repeat(21, 1fr);
       grid-template-rows: 1fr;
       gap: 0px 0px;
       padding: 74px 0;

       .ngp-form {
           grid-column: 2 / 21;
       }

       .at-inner {
          align-content: start;
          align-items: start;
          display: grid;
          grid-template-columns: repeat(19, 1fr);
          grid-template-rows: repeat(6, auto);
       }

       .at-title {
           grid-column: 1 / 10;
           grid-row: 1;
       }

       .HeaderHtml {
          grid-column: 1 / 10;
          grid-row: 2;
       }

       .FooterHtml {
            grid-column: 1 / 10;
            grid-row: 5;
        }

        form {
            align-self: start;
            grid-column: 11 / 20;
            grid-row: 1 / 6;
            margin-top: 0;
        }

        .FirstName {
           margin-top: 0;
       }

        textarea {
           min-height: 153px;
       }

       .at-error-console {
             align-self: start;
             grid-column: 1 / 9;
             grid-row: 4;
             margin-bottom: 0;
             margin-top: 18px;
        }

        .at-check .at-checkbox-title-container span {
            font-size: 14px;
        }
   }

   @media screen and (min-width: '#{$x-large-breakpoint}px') {
      display: grid;
      grid-template-columns: repeat(21, 1fr);
      grid-template-rows: 1fr;
      gap: 0px 0px;
      padding: 74px 0;

      .ngp-form {
          grid-column: 2 / 21;
      }

      .at-inner {
         align-content: start;
         align-items: start;
         display: grid;
         grid-template-columns: repeat(17, 1fr);
         grid-template-rows: repeat(6, auto);
      }

      .at-title {
          grid-column: 1 / 9;
          grid-row: 1;
      }

      .HeaderHtml {
         grid-column: 1 / 9;
         grid-row: 2;
      }

      .FooterHtml {
           grid-column: 1 / 9;
           grid-row: 5;
       }

       form {
           align-self: start;
           grid-column: 10 / 18;
           grid-row: 1 / 6;
       }

       .at-title,
       .thankYou h2,
       .thankYou h3,
       .thankYou h4 {
             @include font-sizing('x-large');
       }
    }
}
