//*------------------------------------*\
//    $COLOR PALETTE
//*------------------------------------*/

$colors: (
    black: rgba(0, 0, 0, 1),
    lightblue: #BBEBFF,
    mediumblue: #1076D0,
    darkblue: #0f5898,
    red: rgba(210,38,48,1),
    text: rgba(0, 0, 0, 1),
    white: rgba(255,255,255,1),
);
