//*------------------------------------*\
//    $TYPOGRAPHY
//*------------------------------------*/

@font-face{
  font-family:'Gotham Bold';
  src: url(/fonts/GothamBold.woff) format("opentype");
}

@font-face{
  font-family:'Gotham Book';
  src: url(/fonts/GothamBook.woff) format("opentype");
}

html, body {
 color: map-get($colors, text);
  font: #{$font-size-default}/#{$line-height-default} $copy-font;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5 {
  font: #{$font-size-default}/#{$line-height-default} $display-font;
  font-weight: map-get($font-weights, normal);
  margin: 0;
}

a {
    .button {
        background-color: #BBEBFF;
        display: block;
        padding: 18px 24px;
    }
}
