.logos {
    $self: &;

    color: $navy;
    text-align: center;

    .component__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        max-width: 900px;
        padding: 60px 20px 80px;
    }

    &__headline {
        @include headline;

        margin-bottom: 60px;
    }

    &__section {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px 0;
        width: 100%;

        &:not(:first-of-type) {
            border-top: 1px solid $cyan;
        }
    }

    &__subhead {
        @include subhead;

        text-transform: uppercase;
    }

    &__logo {
        align-self: center;
        max-width: 180px;
    }

    &__logolist {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 60px 40px;
        margin: 50px 0;
        width: 100%;
    }
}
