//*------------------------------------*\
//    $MODAL
//*------------------------------------*/

.modal {
    display: none;

    &.show {
        display: block;
        margin-top: 125px;

        @media screen and (min-width: '#{$large-breakpoint}px') {
            margin-top: 0;
        }
    }

    &__contents {
        padding: 0 0 60px 0;

        @media screen and (min-width: '#{$large-breakpoint}px') {
            display: grid;
            grid-template-columns: repeat(27, 1fr);
            grid-template-rows: 1fr;
            gap: 0px 0px;
            padding: 125px 0;

            & > .signup,
            & > section {
                grid-column: 4 / 25;
            }
        }
    }

    &__close {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10000;
    }
}
