//*------------------------------------*\
//    $SPACING
//*------------------------------------*/

$padding-base: 8px;

$navheight: (
    small: 68px,
    large: 100px
);
