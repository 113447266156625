//*------------------------------------*\
//    $MIXINS
//*------------------------------------*/

// ======================================
// Font Sizing
// ======================================

@mixin font-sizing($size : 'default') {
    font-size: px-to-rem(map-get($font-sizes, $size));
    line-height: px-to-rem(map-get($line-height, $size));
}

// ======================================
// Vertical Align
// ======================================

@mixin vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// ======================================
// Horizontal Align
// ======================================

@mixin horizontal-align {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin vertical-horizontal-align {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// ======================================
// Visually Hidden
//
// Visually hide the element from the
// screen but still have it accessible
// via screenreaders
//
// ======================================
@mixin isVisuallyHidden() {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

// ======================================
// Ellipsis
// ======================================

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
