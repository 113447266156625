//*------------------------------------*\
//    $TIMELINE
//*------------------------------------*/

.timeline {
    animation: 2s ease-out 1s 1 normal both running fade;
    height: 100vh;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    top: 0;

    width: 100vw;

    .timeline-stage & {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__slider {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        width: 200vw;
    }

    &__slide {
        color: map-get($colors, white);
        height: 500px;
        width: 100vw;

        &-headline {
            font-family:'Gotham Bold';
            font-size: px-to-rem(20px);
            margin-bottom: 38px;
            position: relative;
            text-align: center;

            &:before {
                background-image: url('/images/dot.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100%;
                content: '';
                display: block;
                height: 20px;
                left: calc(50% - 10px);
                position: absolute;
                bottom: -28px;
                width: 20px;
                z-index: 2;
            }
        }

        &-content {
            font-size: px-to-rem(24px);
            line-height: px-to-rem(36px);
            margin: 0 auto 30px auto;
            padding: 0 40px;
        }

        .button {
            margin: 0 auto 50px auto;
        }

        &:first-of-type {
            .timeline__slide-headline:after {
                background-color: map-get($colors, white);
                content: '';
                display: block;
                height: 3px;
                left: 50%;
                position: absolute;
                bottom: -20px;
                width: 50%;
                z-index: 1;
            }
        }

        &:last-of-type {
            .timeline__slide-headline:after {
                background-color: map-get($colors, white);
                content: '';
                display: block;
                height: 3px;
                left: 0;
                position: absolute;
                bottom: -20px;
                width: 50%;
                z-index: 1;
            }
        }
    }

    &.show__2 .timeline__slider {
        transition: transform 1s ease-in-out;
        transform: translateX(-50%);
    }

    @media screen and (min-width: '#{$large-breakpoint}px') {
        &__slide {
            height: 400px;

            &-headline {
                margin-bottom: 56px;
            }

            &-content {
                padding: 0;
                width: 51vw;
            }
        }
    }
}
