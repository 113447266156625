//*------------------------------------*\
//    $TYPOGRAPHIC VARIABLES
//*------------------------------------*/

// Defaults
$font-size-default: 14px;
$line-height-default: 22px;

/* Font weights */

$font-weights: (
    regular:400,
    medium: 500,
    bold:600,
    bolder:700
);

/* Font stack */

$display-font: 'Gotham Bold', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$copy-font: 'Gotham Book', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

/* Letter Spacing */
$letter-spacing: (
    wide: .15rem
);

$font-sizes: (
    small: 10px,
    default: $font-size-default,
    medium: 16px,
    large: 36px,
    x-large: 50px
);

/* Line Heights */
$line-height: (
    small: 16px,
    default: $line-height-default,
    medium: 20px,
    large: 40px,
    x-large: 54px
);
