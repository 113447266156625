.call-to-action {
    &--light {
        color: $navy;

        .component__background {
            background-color: $cyan-light;
        }
    }

    &--dark {
        color: $white;

        .component__background {
            background-color: $navy;
        }
    }

    .component__content {
        margin: 0 auto;
        max-width: 1200px;
        padding: 60px 20px 80px;
        text-align: center;

        @include bp($bp-sm) {
            align-items: center;
            display: flex;
            gap: 0 60px;
            justify-content: center;
            text-align: left;
        }
    }

    &--no-body {
        .component__content {
            @include bp($bp-sm) {
                text-align: center;
            }
        }

        .call-to-action__copy {
            flex-basis: 100%;
        }
    }

    &__image {
        @include bp($bp-sm) {
            flex-basis: 50%;
        }
    }

    &__copy {
        @include bp($bp-sm) {
            flex-basis: 50%;
            max-width: 600px;
        }
    }

    &__smallimage {
        border-bottom: 2px solid $cyan;
        margin-bottom: 40px;
        padding-bottom: 50px;
        padding-top: 30px;

        img {
            display: block;
            margin: 0 auto;
            width: 155px;
        }
    }

    &__headline {
        @include headline;

        margin-bottom: 25px;
    }

    &__body p {
        color: inherit;
        font-family: gotham_htfbook;
        font-size: 17px;
        line-height: 1.55;
        margin-bottom: 15px;
    }

    &__link {
        display: inline-block;
        margin-top: 20px;
    }
}
