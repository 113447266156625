.hero__mainlogo {
    display: block;
    margin: 90px auto 35px;
    max-width: 525px;
}

.hero__secondarylogo {
    display: block;
    margin: 0 auto 74px;
    max-width: 285px;
}

.hero__middle-intro {
    padding-bottom: 0;

    h1 {
        font-family: gotham_htfbook;
        font-size: 32px;
        line-height: 1.3;
        text-transform: none;

        @include bp($bp-sm) {
            font-size: 35px;
        }
    }
}

@mixin button() {
    background: $cyan;
    border-radius: 0;
    box-shadow: none;
    display: inline-block;
    color: $black;
    font-family: gotham_htfbold;
    font-size: 17px;
    letter-spacing: 0.05em;
    padding: 10px 20px;
}

.button {
    @include button;
}

.hero__middle-content {
    background: none;
    font-family: gotham_htfbook;
    max-width: 600px;
    text-align: center;

    a {
        @include button;
    }

    p {
        font-size: 20px;
        line-height: 1.55;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    h1 {
        font-size: 40px;
        line-height: 1.3;
        text-transform: none;
    }

    h2 {
        font-size: 18px;
        line-height: 1.5;
        text-transform: uppercase;
    }

    h3 {
        font-size: 15px;
        line-height: 1.5;
        text-transform: none;
    }

    ul {
        padding-left: 35px;
        width: 100%;

        li {
            font-size: 19px;
            line-height: 1.55;
        }
    }

    img {
        max-width: 450px;
    }
}

.hero__middle-content-copy {
    border-bottom: 2px solid $cyan;
    border-top: 2px solid $cyan;
    padding: 60px 0;
    text-align: left;
}

.hero__middle-content--image {
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
}

img {
    max-width: none;
    width: 100%;
}
