.video {
    background-color: $black;
    padding-bottom: 50px;
    padding-top: 50px;

    &__embed {
        div {
            display: block;
            max-width: none !important;
            position: relative;

            div {
                padding-top: 56.25%;
            }
        }

        iframe {
            border: none;
        }
    }
}
